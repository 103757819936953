<template>
    <div>
        <!--:visible="visible"  赋值 显示变量-->
        <!--@close="onClose"    赋值 关闭函数-->
        <a-drawer title="创建样品分类" width="640" placement="right" :closable="true" :visible="visible" @close="onClose">
            <div>
                <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
                    <a-form-model-item label="名称">
                        <a-input v-model="form.name"/>
                    </a-form-model-item>
                    <a-form-model-item label="排序">
                        <a-input v-model="form.order"/>
                    </a-form-model-item>
                    <a-form-model-item label="" :wrapper-col="{ span: 14, offset: 4 }">
                        <a-checkbox v-model="auto_choose" value="1" name="type">
                            创建之后选中
                        </a-checkbox>
                    </a-form-model-item>
                    <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
                        <a-button type="primary" @click="onSubmit">
                            创建
                        </a-button>
                        <br>
                        <a>前往样品分类管理页面></a>
                    </a-form-model-item>
                </a-form-model>
            </div>
        </a-drawer>
    </div>
</template>
<script>
    /***
     Name: example3
     Code: sa0ChunLuyu
     Time: 2021/10/16 09:47
     Remark: 编辑抽屉示例的抽屉组件
     */
    export default {
        // 接收 显示变量 和 关闭函数
        props: ['visible', 'close', 'chooseDo'],
        data() {
            return {
                auto_choose: true,
                labelCol: {span: 4},
                wrapperCol: {span: 14},
                form: {
                    name: '',
                    order: '0'
                },
            }
        },
        mounted() {
        },
        methods: {
            // 定义 组件 自身的关闭函数
            onClose() {
                this.close();
            },
            onSubmit() {
                this.$sa0.post({
                    url: this.$api('Create_sampletype'),
                    data: {
                        name: this.form.name,
                        sort: this.form.order,
                        type: '1',
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.chooseDo(this.auto_choose, response.data.id);
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
                console.log('submit!', this.form);
            },
        }
    }
</script>
<style scoped>

</style>
