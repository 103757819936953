<template>
    <div class="wraper">
        <div class="wrap-mainer">
            <div class="bgc sidebar">
                <div class="samplelefttit">
                    样品类型
                </div>
                <div  class="sidebarmain">
                    <a-tree :tree-data="node_list" :expandedKeys.sync="expandedKeys" :replaceFields="{children:'child', title:'name', key:'id' }" :show-line="showLine" :show-icon="showIcon" @select="onSelect">
                        <a-icon slot="icon" type="carry-out"/>
                    </a-tree>
                </div>
                    <div class="addbutton">
                        <div class="addbuttonbtn" @click="createClick(0)" >
                            添加分类
                        </div>
                    </div>

            </div>
            <div  class="wrap-container">
                <!--   创建编辑样品类型-->
                <div class="createsmaplewrap" v-show="createyangpinfenlei">
                    <div class="createsmaple">
                        <div class="createsmapletit">{{ do_type?'编辑':'创建' }}样品类型</div>
                        <div v-if="do_type">
                            <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" okType="danger" @confirm="delClick">
                                <template slot="title">
                                    确认删除该项？
                                </template>
                                <a-button type="danger" class="ml20">
                                    删除
                                </a-button>
                            </a-popconfirm>

                        </div>
                    </div>
                    <div class="mt20">
                        <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
                            <a-form-model-item label="名称">
                                <a-input v-model="form.name" class="w300"/>
                            </a-form-model-item>

                            <a-form-model-item label="样品分类" v-if="false">
                                <a-select v-model="form.region" placeholder="请选择样品分类" class="w300">
                                    <a-select-option v-for="(item,key ) in type_list" :key="key" :value="item.id">
                                        {{ item.name}}
                                    </a-select-option>
                                </a-select>
                                <span @click="getStype()" class="ant-form-text"><a-icon type="sync"/></span>
                                <a-button type="primary" @click="showDrawer">创建样品分类</a-button>
                            </a-form-model-item>

                            <a-form-item :wrapper-col="{ span: 12, offset: 4 }">
                                <a-button @click="doClick2()" type="primary" html-type="submit">保存</a-button>
                            </a-form-item>


                        </a-form-model>
                    </div>
                </div>

                <!--   创建编辑分类-->
                <div class="createsmaplewrap" v-show="createfenlei">
                    <div class="createsmaple">
                        <div class="createsmapletit">{{ do_type?'编辑':'创建' }}分类</div>
                        <div v-if="do_type">
                            <a-button @click="createNewList()" type="primary" class="ml20">
                                创建分类
                            </a-button>
                            <a-button type="primary" class="ml20" @click="createNewNode()">
                                创建样品类型
                            </a-button>
                            <a-popconfirm placement="top" ok-text="确定" cancel-text="取消" okType="danger" @confirm="delClick">
                                <template slot="title">
                                    确认删除该项？
                                </template>
                                <a-button type="danger" class="ml20">
                                    删除
                                </a-button>
                            </a-popconfirm>
                        </div>
                    </div>
                    <div class="mt20">
                        <a-form-model :model="form1" :label-col="labelCol" :wrapper-col="wrapperCol">
                            <a-form-model-item label="名称">
                                <a-input v-model="form1.name" class="w300"/>
                            </a-form-model-item>
                            <a-form-item :wrapper-col="{ span: 12, offset: 4 }">
                                <a-button type="primary" html-type="submit" @click="doClick()">
                                    保存
                                </a-button>
                            </a-form-item>
                        </a-form-model>
                    </div>
                </div>

            </div>
        </div>
        <!--创建样品分类弹窗-->
        <Addsampletype :chooseDo="chooseDo" :close="onCloseDrawer" :visible="drawer_visible"></Addsampletype>
    </div>
</template>
<script>
    import Addsampletype from '../../../components/drawer/addsampletype/index.vue';
    export default {
        props: ['visible', 'close'],
        components: {
            Addsampletype
        },
        data() {
            return {
                expandedKeys: [],
                type_list: [],
                openArr: [0],
                do_type: false,
                pid: 0,
                node_list: [],
                createyangpinfenlei: false,
                createfenlei: false,
                showLine: true,
                showIcon: false,
                // 定义控制抽屉显示的变量
                drawer_visible: false,
                labelCol: {span: 4},
                wrapperCol: {span: 14},
                form: {
                    name: '',
                    region: '0',
                },
                form1: {
                    id: '0',
                    name: '',
                    type: '1',
                    pid: '0',
                },
                sample_category: false,
                p_info: false,
            };
        },
        watch: {
            $route: {
                handler() {
                    console.log('handler')
                    this.Samplecategoryalllist()
                    this.mountedDo()
                },
                deep: true
            }
        },
        mounted() {
            this.Samplecategoryalllist()
            this.mountedDo()
            this.getStype()
        },
        methods: {
            chooseDo(auto, id) {
                this.getStype()
                if (auto) {
                    this.form.region = Number(id)
                }
                this.onCloseDrawer()
            },


            getStype() {
                this.$sa0.post({
                    url: this.$api('Sampletypelist'),
                    data: {
                        type: '1'
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.type_list = response.data.list
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            createNewList() {
                this.form1 = {
                    id: '0',
                    name: '',
                    type: '0',
                    pid: this.pid,
                    type_id: '0'
                };
                this.createfenlei = true;
                this.createyangpinfenlei = false;
                this.do_type = false
            },
            mountedDo() {
                this.pid = this.$route.params.id
                if (this.pid == 0) {
                    this.form1 = {
                        name: '',
                        type: '0',
                        pid: this.pid,
                        type_id: '0'
                    }
                    this.createfenlei = true;
                    this.createyangpinfenlei = false;
                    this.do_type = false
                } else {
                    this.getItemInfo()
                }
            },
            getItemInfo() {
                this.do_type = true
                this.$sa0.post({
                    url: this.$api('get_samplecategory_detail'),
                    data: {
                        id: this.pid
                    },
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.sample_category = response.data.sample_category;
                            this.expandedKeys = ((this.sample_category.sample_category_ids).toString()).split(',').map((item)=>{
                                return Number(item)
                            })
                            this.openArr = [this.sample_category.pid]
                          console.log(JSON.stringify(response.data))
                            if (this.sample_category.type == 0) {
                                this.createfenlei = true;
                                this.createyangpinfenlei = false;
                            } else {
                                this.createfenlei = false;
                                this.createyangpinfenlei = true;
                            }
                            this.form1 = {
                                id: this.sample_category.id,
                                name: this.sample_category.name,
                                type: this.sample_category.type,
                                pid: this.sample_category.pid,
                                type_id: this.sample_category.type_id,
                            }
                            this.form = {
                                id: this.sample_category.id,
                                name: this.sample_category.name,
                                type: this.sample_category.type,
                                pid: this.sample_category.pid,
                                region: Number(this.sample_category.type_id),
                                type_id: this.sample_category.type_id,
                            }
                            console.log(this.sample_category.type_id)
                            console.log(this.form.region)
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })

                // if (this.pid != 0) {
                //     layer.msg('获取 ' + this.pid + '的信息')
                // }
            },
            Samplecategoryalllist() {
                this.$sa0.post({
                    url: this.$api('Samplecategoryalllist'),
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.node_list = response.data.list;
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            delClick() {
                this.$sa0.post({
                    url: this.$api('del_samplecategory'),
                    data: {
                        id: this.sample_category.id,
                    }
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            layer.msg(response.message)
                            this.Samplecategoryalllist()
                            this.$router.push('/sampletype/index/0')
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },

            // 定义 关闭抽屉时的 函数
            onCloseDrawer() {
                this.drawer_visible = false;
            },
            // 定义 打开抽屉时的 函数
            showDrawer() {
                this.drawer_visible = true;
            },
            onSelect(selectedKeys) {
                if (selectedKeys.length == 0) return false;
                if (Number(this.pid) === Number(selectedKeys[0])) return false;
                this.$router.push('/sampletype/index/' + selectedKeys[0])
            },
            createClick(pid) {
                if (Number(this.pid) === Number(pid)) return false;
                this.$router.push('/sampletype/index/' + pid)
            },
            doClick2() {
                console.log(this.form.region)
                let data = {
                    id: this.do_type ? this.form.id : '0',
                    name: this.form.name,
                    type: '1',
                    type_id: this.form.region,
                    pid: this.form.pid,
                }
                this.$sa0.post({
                    url: this.do_type ? this.$api('edit_samplecategory') : this.$api('Create_samplecategory'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            layer.msg(res.message)
                            if (this.do_type) {
                                this.Samplecategoryalllist()
                                this.mountedDo()
                            } else {
                                this.$router.push('/sampletype/index/' + res.data.id)
                            }

                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },
            doClick() {
                let data = {
                    id: this.do_type ? this.form1.id : '0',
                    name: this.form1.name,
                    type: '0',
                    type_id: '2',
                    pid: this.form1.pid,
                }
                this.$sa0.post({
                    url: this.do_type ? this.$api('edit_samplecategory') : this.$api('Create_samplecategory'),
                    data: data,
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (res) => {
                            layer.msg(res.message)
                            if (this.do_type) {
                                this.Samplecategoryalllist()
                                this.mountedDo()
                            } else {
                                this.$router.push('/sampletype/index/' + res.data.id)
                            }


                        },
                        error: (res) => {
                            layer.msg(res.message)
                        }
                    })
                })
            },
            createNewNode() {
                this.form = {
                    id: '0',
                    name: '',
                    type: '1',
                    pid: this.pid,
                    region: this.type_list[0].id,
                    type_id: '0'
                };
                this.createfenlei = false;
                this.createyangpinfenlei = true;
                this.do_type = false
            }
        },
    };
</script>

<style scoped>

    .wraper{ width: 100%; height: 100%; display: flex;}

    .wrap-mainer{ flex: 1;display: flex; }

    .sidebar{ width: 250px;  display: flex; flex-direction: column}
    .sidebarmain{ flex: 1; overflow-y: auto}

    .wrap-container{ flex:1; overflow-y: auto}

    .addbutton{ width: 100%; text-align: center; bottom: 0; height: 40px; background: #1890ff}
    .addbuttonbtn{ width: 100%; color: #ffffff; line-height: 40px; cursor: pointer; height: 40px}

    .bgc{ margin-right: 20px; border: 1px solid #eeeeee;}

    .samplelefttit{ background: #f9f9f9; padding: 10px; border-bottom: 1px solid #eeeeee; font-weight: bold }



    .createsmaplewrap{ width: 800px; }

    .createsmaple{ width: 800px; display: flex; justify-content: space-between}

    .createsmapletit{ font-size: 20px; font-weight: bold}

    .mt20{ margin-top: 20px}

    .w300{ width: 300px}

    .ant-form-text{ display: inline-block; padding-left: 20px; cursor: pointer}

    .ml20{ margin-left: 20px}

</style>
